import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { EditUserComponent } from 'src/app/popovers/edit-user/edit-user.component';
import { AuthService } from 'src/services/auth.service';
import { UserService } from 'src/services/user.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  user: User = <User>{};
  userSub: Subscription;
 
  public settings: any;
  constructor(
    public userService: UserService,
    public authService: AuthService,
    public popoverController: PopoverController
  ) {}

  ngOnInit() {
    this.authService.autoAuthUser();
    this.userService.getUserById(this.authService.getUserId());

    this.userSub = this.userService
      .getUserUpdateListener()
      .subscribe((user: User) => {
        this.user.name = user.name;
        this.user.phone = user.phone;
        this.user.theme = user.theme;
        if (this.user.theme != false) {
          document.body.setAttribute('color-theme', 'dark');
        } else {
          document.body.setAttribute('color-theme', 'light');
        }
      });
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
  
  async editSetting(user: User, index: number) {
    const popover = await this.popoverController.create({
      component: EditUserComponent,
      componentProps: { user: user, index: index },
      cssClass: 'my-custom-class',
      // event: ev,
      translucent: true,
    });
    await popover.present();
  }

  toggleTheme(event) {
    if (event.detail.checked) {
      document.body.setAttribute('color-theme', 'dark');
      this.user.theme = true;
      this.userService.updateUserTheme(true);
    } else {
      document.body.setAttribute('color-theme', 'light');
      this.user.theme = false;
      this.userService.updateUserTheme(false);
    }
  }
}
