import { Component, OnInit } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import { TicketService } from 'src/services/ticket.service';
import { Service } from '../../models/service';

@Component({
  selector: 'app-purchase-service',
  templateUrl: './purchase-service.component.html',
  styleUrls: ['./purchase-service.component.scss'],
})
export class PurchaseServiceComponent implements OnInit {
  public service: Service;
  constructor(
    public toastController: ToastController,
    public popoverController: PopoverController,
    public ticketService: TicketService
  ) {}

  ngOnInit() {}
  purchaseService() {
    this.ticketService.addTicket(
      this.service.name,
      this.service.description,
      this.service.price,
      this.service.creditsPrice,
      this.service.creditsEarn,
    );
    
    this.presentToastSuccess();
  }

  async presentToastSuccess() {
    const toast = await this.toastController.create({
      message: 'Закажана е вашиот сервис!',
      duration: 2000,
    });
    toast.present();
    this.dismissPopover();
  }

  async dismissPopover(showAddressModal = false) {
    await this.popoverController.dismiss({ showAddress: showAddressModal });
  }
}
