import { Component, OnInit } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import { Product } from 'src/app/models/product';
import { ProductService } from 'src/services/product.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent implements OnInit {
  product: Product = <Product>{};

  constructor(
    public toastController: ToastController,
    public popoverController: PopoverController,
    public productService: ProductService
  ) {}

  ngOnInit() {}

  addProduct(form: any) {
    this.productService.addProduct(
      form.value.name,
      form.value.description,
      form.value.price,
      form.value.creditsPrice,
      form.value.creditsEarn
    );
    this.presentToastSuccess();
  }

  async presentToastSuccess() {
    const toast = await this.toastController.create({
      message: 'Додадовте нов продукт!',
      duration: 1500,
    });
    toast.present();
    this.dismissPopover();
  }

  async dismissPopover(showAddressModal = false) {
    await this.popoverController.dismiss({ showAddress: showAddressModal });
  }
}
