import { Injectable } from '@angular/core';
import { Product } from '../app/models/product';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ExternalHost } from './variables';

@Injectable({ providedIn: 'root' })
export class ProductService {
  products: Product[] = [];
  productsUpdated = new Subject<Product[]>();
  product: Product;
  productUpdated = new Subject<Product>();
  myPageId: string;
  myProductIdUpdated = new Subject<string>();
  externalHost: string = ExternalHost;

  constructor(private http: HttpClient) {}

  getProductsUpdateListener() {
    return this.productsUpdated.asObservable();
  }
  getProductUpdateListener() {
    return this.productUpdated.asObservable();
  }
  getMyProductIdUpdateListener() {
    return this.myProductIdUpdated.asObservable();
  }
  getMyPageId() {
    return this.myPageId;
  }

  getProducts() {
    this.http
      .get<{ message: string; products: Product[] }>(
        this.externalHost + '/products'
      )
      .pipe(
        map((productData) => {
          return productData.products.map((product) => {
            return {
              _id: product._id,
              name: product.name,
              description: product.description,
              price: product.price,
              creditsPrice: product.creditsPrice,
              creditsEarn: product.creditsEarn,
            };
          });
        })
      )
      .subscribe((transformedProducts) => {
        this.products = transformedProducts;
        this.productsUpdated.next([...this.products]);
      });
  }

  getProductById(productId: string) {
    this.http
      .get<{ message: string; product: Product }>(
        this.externalHost + '/products/' + productId
      )
      .pipe(
        map((productData) => {
          return {
            _id: productData.product._id,
            name: productData.product.name,
            description: productData.product.description,
            price: productData.product.price,
            creditsPrice: productData.product.creditsPrice,
            creditsEarn: productData.product.creditsEarn,
          };
        })
      )
      .subscribe((transformedProduct) => {
        this.product = transformedProduct;
        this.productUpdated.next(this.product);
      });
  }

  addProduct(
    productName: string,
    productDescription: string,
    productPrice: number,
    productCreditsPrice: number,
    productCreditsEarn: number
  ) {
    const product: Product = {
      _id: null,
      name: productName,
      description: productDescription,
      price: productPrice,
      creditsPrice: productCreditsPrice,
      creditsEarn: productCreditsEarn,
    };
    this.http
      .post<{ message: string; productId: string }>(
        this.externalHost + '/products',
        product
      )
      .subscribe((responseData) => {
        const id = responseData.productId;
        product._id = id;
        this.products.push(product);
        this.productsUpdated.next([...this.products]);
      });
  }

  removeProduct(productId: string) {
    this.http
      .delete(this.externalHost + '/products/' + productId)
      .subscribe((message) => {
        const updatedProduct = this.products.filter(
          (product) => product._id !== productId
        );
        this.products = updatedProduct;
        this.productsUpdated.next([...this.products]);
      });
  }

  // private saveProducts(products: Product[]) {
  //   localStorage.setItem('products', JSON.stringify(products));
  // }
}
