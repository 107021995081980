import { Injectable } from '@angular/core';
import { User } from '../app/models/user';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Ticket } from 'src/app/models/ticket';
import { ExternalHost } from './variables';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  adminStatus: number = 1;
  adminUsers: User[] = [<User>{}];
  adminUsersUpdated = new Subject<User[]>();
  users: User[] = [<User>{}];
  usersUpdated = new Subject<User[]>();
  user: User = <User>{};
  userUpdated = new Subject<User>();
  myPageId: string;
  myUserIdUpdated = new Subject<string>();
  externalHost: string = ExternalHost;

  constructor(private http: HttpClient) {}

  getUsersUpdateListener() {
    return this.usersUpdated.asObservable();
  }
  //Admin users
  getAdminUsersUpdateListener() {
    return this.adminUsersUpdated.asObservable();
  }
  getUserUpdateListener() {
    return this.userUpdated.asObservable();
  }
  getMyUserIdUpdateListener() {
    return this.myUserIdUpdated.asObservable();
  }
  getMyPageId() {
    return this.myPageId;
  }
  getUserCredits() {
    return this.user.credits;
  }
  getUser() {
    return this.user;
  }

  getAdminUsers() {
    this.http
      .get<{ message: string; adminUsers: User[] }>(
        this.externalHost + '/users/adminStatus/' + this.adminStatus
      )
      .pipe(
        map((userData) => {
          return userData.adminUsers.map((user) => {
            return {
              _id: user._id,
              phone: user.phone,
              admin_status: user.admin_status,
              name: user.name,
              credits: user.credits,
              theme: user.theme,
            };
          });
        })
      )
      .subscribe((transformedUsers) => {
        this.adminUsers = transformedUsers;
        this.adminUsersUpdated.next([...this.adminUsers]);
      });
  }
  getUserCreditsById(userId: string) {
    this.http
      .get<{ message: string; user: User }>(
        this.externalHost + '/users/' + userId
      )
      .pipe(
        map((userData) => {
          return {
            credits: userData.user.credits,
          };
        })
      )
      .subscribe((transformedUser) => {
        this.user.credits = transformedUser.credits;
        this.userUpdated.next(this.user);
      });
  }

  getUserById(userId: string) {
    this.http
      .get<{ message: string; user: User }>(
        this.externalHost + '/users/userById/' + userId
      )
      .pipe(
        map((userData) => {
          return {
            _id: userData.user._id,
            phone: userData.user.phone,
            admin_status: userData.user.admin_status,
            name: userData.user.name,
            credits: userData.user.credits,
            theme: userData.user.theme,
          };
        })
      )
      .subscribe((transformedUser) => {
        this.user = transformedUser;
        this.userUpdated.next(this.user);
      });
  }

  consumeUserCredits(ticketCreditsPrice: number, value: any) {
    const User = {
      _id: this.user._id,
      phone: this.user.phone,
      admin_status: this.user.admin_status,
      name: this.user.name,
      credits: this.user.credits,
    };
    if (value == 'credits' && this.user.credits >= ticketCreditsPrice) {
      this.user.credits = this.user.credits - ticketCreditsPrice;
      User.credits = this.user.credits;
    }

    this.http
      .patch<{ message: string; user: User }>(
        this.externalHost + '/users/updateUserCredits/' + this.user._id,
        User
      )
      .subscribe((transformedUser) => {
        this.user = transformedUser.user;
        this.userUpdated.next(this.user);
      });
  }

  updateUserCredits(ticketCreditsEarn: number) {
    const User = {
      _id: this.user._id,
      phone: this.user.phone,
      admin_status: this.user.admin_status,
      name: this.user.name,
      credits: this.user.credits,
    };
    this.user.credits = this.user.credits + ticketCreditsEarn;
    User.credits = User.credits + ticketCreditsEarn;

    this.http
      .patch<{ message: string; userCredits: number }>(
        this.externalHost + '/users/' + this.user._id,
        User
      )
      .subscribe((transformedUser) => {
        this.user.credits = transformedUser.userCredits;
        this.userUpdated.next(this.user);
      });
  }

  updateUserName(editedUser: any) {
    const User = {
      _id: this.user._id,
      name: editedUser.name,
      phone: this.user.phone,
      // password: editedUser.password,
      admin_status: this.user.admin_status,
      credits: this.user.credits,
    };
    this.http
      .patch<{ message: string; userName: string }>(
        this.externalHost + '/users/updateUserName/' + this.user._id,
        User
      )
      .subscribe((transformedUser) => {
        this.user.name = transformedUser.userName;
        this.userUpdated.next(this.user);
      });
  }
  updateUserPhone(editedUser: any) {
    const User = {
      _id: this.user._id,
      name: this.user.name,
      phone: editedUser.phone,
      //password: editedUser.password,
      admin_status: this.user.admin_status,
      credits: this.user.credits,
    };
    this.http
      .patch<{ message: string; userPhone: number }>(
        this.externalHost + '/users/updateUserPhone/' + this.user._id,
        User
      )
      .subscribe((transformedUser) => {
        this.user.phone = transformedUser.userPhone;
        this.userUpdated.next(this.user);
      });
  }
  updateUserPassword(editedUser: any) {
    const User = {
      _id: this.user._id,
      name: this.user.name,
      phone: this.user.phone,
      password: editedUser.password,
      admin_status: this.user.admin_status,
      credits: this.user.credits,
    };

    this.http
      .patch<{ message: string; userPassword: string }>(
        this.externalHost + '/users/updateUserPassword/' + this.user._id,
        User
      )
      .subscribe((transformedUser) => {
      });
  }

  updateUserTheme(theme) {
    const User = {
      _id: this.user._id,
      name: this.user.name,
      phone: this.user.phone,
      admin_status: this.user.admin_status,
      credits: this.user.credits,
      theme: theme,
    };
    this.http
      .patch<{ message: string; theme: boolean }>(
        this.externalHost + '/users/updateUserTheme/' + this.user._id,
        User
      )
      .subscribe((transformedUser) => {
        this.user.theme = transformedUser.theme;
        this.userUpdated.next(this.user);
      });
  }
  addAdmin(userPhone: number) {
    const User = {
      _id: this.user._id,
      name: this.user.name,
      phone: this.user.phone,
      admin_status: 1,
      credits: this.user.credits,
      theme: this.user.theme,
    };
    this.http
      .patch<{ message: string; adminStatus: number }>(
        this.externalHost + '/users/addAdmin/' + userPhone,
        User
      )
      .subscribe((transformedUser) => {
        this.user.admin_status = transformedUser.adminStatus;
        this.userUpdated.next(this.user);
        this.getAdminUsers();
      });
  }
  removeAdmin(id: string) {
    const User = {
      _id: this.user._id,
      name: this.user.name,
      phone: this.user.phone,
      admin_status: 2,
      credits: this.user.credits,
      theme: this.user.theme,
    };
    this.http
      .patch<{ message: string; adminStatus: number }>(
        this.externalHost + '/users/removeAdmin/' + id,
        User
      )
      .subscribe((transformedUser) => {
        this.user.admin_status = transformedUser.adminStatus;
        this.userUpdated.next(this.user);
        this.getAdminUsers();
      });
  }

  removeUser(userId: string) {
    this.http
      .delete(this.externalHost + '/users/' + userId)
      .subscribe((message) => {
        const updatedUser = this.users.filter((user) => user._id !== userId);
        this.users = updatedUser;
        this.usersUpdated.next([...this.users]);
      });
  }
}
