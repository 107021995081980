import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.scss'],
})
export class AddAdminComponent implements OnInit {
  adminInputPhone: number;
  adminInputName: string;
  constructor(
    public popoverController: PopoverController,
    public userService: UserService
  ) {}

  ngOnInit() {}

  addAdmin() {
    this.userService.addAdmin(this.adminInputPhone);
    this.dismissPopover();
  }

  async dismissPopover(showAddressModal = false) {
    await this.popoverController.dismiss({ showAddress: showAddressModal });
  }
}
