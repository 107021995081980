import { Component, OnInit } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import { TicketService } from 'src/services/ticket.service';
import { Product } from '../../models/product';

@Component({
  selector: 'app-purchase-product',
  templateUrl: './purchase-product.component.html',
  styleUrls: ['./purchase-product.component.scss'],
})
export class PurchaseProductComponent implements OnInit {
  public product: Product;
  constructor(
    public toastController: ToastController,
    public popoverController: PopoverController,
    public ticketService: TicketService
  ) {}

  ngOnInit() {}

  purchaseProduct() {
    this.ticketService.addTicket(
      this.product.name,
      this.product.description,
      this.product.price,
      this.product.creditsPrice,
      this.product.creditsEarn
    );

    this.presentToastSuccess();
  }

  async presentToastSuccess() {
    const toast = await this.toastController.create({
      message: 'Нарачката е успешмп направена!',
      duration: 2000,
    });
    toast.present();
    this.dismissPopover();
  }

  async dismissPopover(showAddressModal = false) {
    await this.popoverController.dismiss({ showAddress: showAddressModal });
  }
}
