import { Injectable } from '@angular/core';
import { Service } from '../app/models/service';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ExternalHost } from './variables';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  services: Service[] = [];
  servicesUpdated = new Subject<Service[]>();
  service: Service;
  serviceUpdated = new Subject<Service>();
  myPageId: string;
  myServiceIdUpdated = new Subject<string>();
  externalHost: string = ExternalHost;

  constructor(private http: HttpClient) {}

  getServicesUpdateListener() {
    return this.servicesUpdated.asObservable();
  }
  getServiceUpdateListener() {
    return this.serviceUpdated.asObservable();
  }
  getMyServiceIdUpdateListener() {
    return this.myServiceIdUpdated.asObservable();
  }
  getMyPageId() {
    return this.myPageId;
  }

  getServices() {
    this.http
      .get<{ message: string; services: Service[] }>(
        this.externalHost + '/services'
      )
      .pipe(
        map((serviceData) => {
          return serviceData.services.map((service) => {
            return {
              _id: service._id,
              name: service.name,
              description: service.description,
              price: service.price,
              creditsPrice: service.creditsPrice,
              creditsEarn: service.creditsEarn,
            };
          });
        })
      )
      .subscribe((transformedServices) => {
        this.services = transformedServices;
        this.servicesUpdated.next([...this.services]);
      });
  }

  getServiceById(serviceId: string) {
    this.http
      .get<{ message: string; service: Service }>(
        this.externalHost + '/services/' + serviceId
      )
      .pipe(
        map((serviceData) => {
          return {
            _id: serviceData.service._id,
            name: serviceData.service.name,
            description: serviceData.service.description,
            price: serviceData.service.price,
            creditsPrice: serviceData.service.creditsPrice,
            creditsEarn: serviceData.service.creditsEarn,
          };
        })
      )
      .subscribe((transformedService) => {
        this.service = transformedService;
        this.serviceUpdated.next(this.service);
      });
  }

  addService(
    serviceName: string,
    serviceDescription: string,
    servicePrice: number,
    serviceCreditsPrice: number,
    serviceCreditsEarn: number
  ) {
    const service: Service = {
      _id: null,
      name: serviceName,
      description: serviceDescription,
      price: servicePrice,
      creditsPrice: serviceCreditsPrice,
      creditsEarn: serviceCreditsEarn,
    };
    this.http
      .post<{ message: string; serviceId: string }>(
        this.externalHost + '/services',
        service
      )
      .subscribe((responseData) => {
        const id = responseData.serviceId;
        service._id = id;
        this.services.push(service);
        this.servicesUpdated.next([...this.services]);
      });
  }

  removeService(serviceId: string) {
    this.http
      .delete(this.externalHost + '/services/' + serviceId)
      .subscribe((message) => {
        const updatedService = this.services.filter(
          (service) => service._id !== serviceId
        );
        this.services = updatedService;
        this.servicesUpdated.next([...this.services]);
      });
  }
}
