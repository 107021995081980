import { Injectable } from '@angular/core';
import { Ticket } from '../app/models/ticket';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ExternalHost } from './variables';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  tickets: Ticket[] = [];
  ticketsUpdated = new Subject<Ticket[]>();
  ticket: Ticket;
  ticketUpdated = new Subject<Ticket>();
  myPageId: string;
  myTicketIdUpdated = new Subject<string>();
  externalHost: string = ExternalHost;

  constructor(private http: HttpClient, private userService: UserService) {}

  getTicketsUpdateListener() {
    return this.ticketsUpdated.asObservable();
  }
  getTicketsCache() {
    return this.tickets[1];
  }
  getTicketUpdateListener() {
    return this.ticketUpdated.asObservable();
  }
  getMyTicketIdUpdateListener() {
    return this.myTicketIdUpdated.asObservable();
  }
  getMyPageId() {
    return this.myPageId;
  }

  getTicketsByUserId(userId: string) {
    this.http
      .get<{ message: string; tickets: Ticket[] }>(
        this.externalHost + '/tickets/' + userId
      )
      .pipe(
        map((ticketData) => {
          return ticketData.tickets.map((ticket) => {
            return {
              _id: ticket._id,
              name: ticket.name,
              description: ticket.description,
              price: ticket.price,
              date: ticket.date,
              creatorId: ticket.creatorId,
              creatorName: ticket.creatorName,
              finish: ticket.finish,
              creditsPrice: ticket.creditsPrice,
              creditsEarn: ticket.creditsEarn,
            };
          });
        })
      )
      .subscribe((transformedTickets) => {
        this.tickets = transformedTickets;
        this.ticketsUpdated.next([...this.tickets]);
      });
  }

  getTickets() {
    this.http
      .get<{ message: string; tickets: Ticket[] }>(
        this.externalHost + '/tickets'
      )
      .pipe(
        map((ticketData) => {
          return ticketData.tickets.map((ticket) => {
            return {
              _id: ticket._id,
              name: ticket.name,
              description: ticket.description,
              price: ticket.price,
              date: ticket.date,
              creatorId: ticket.creatorId,
              creatorName: ticket.creatorName,
              finish: ticket.finish,
              creditsPrice: ticket.creditsPrice,
              creditsEarn: ticket.creditsEarn,
            };
          });
        })
      )
      .subscribe((transformedTickets) => {
        this.tickets = transformedTickets;
        this.ticketsUpdated.next([...this.tickets]);
      });
  }

  updateFinishTicket(ticket: Ticket) {
    const Ticket = <Ticket>{
      _id: ticket._id,
      finish: ticket.finish,
    };
    ticket.finish = true;
    Ticket.finish = true;
    this.http
      .patch<{ message: string }>(
        this.externalHost + '/tickets/' + ticket._id,
        Ticket
      )
      .subscribe((responseData) => {
      });
  }

  getTicketById(ticketId: string) {
    this.http
      .get<{ message: string; ticket: Ticket }>(
        this.externalHost + '/tickets/' + ticketId
      )
      .pipe(
        map((ticketData) => {
          return {
            _id: ticketData.ticket._id,
            name: ticketData.ticket.name,
            description: ticketData.ticket.description,
            price: ticketData.ticket.price,
            date: ticketData.ticket.date,
            creatorId: ticketData.ticket.creatorId,
            creatorName: ticketData.ticket.creatorName,
            finish: ticketData.ticket.finish,
            creditsPrice: ticketData.ticket.creditsPrice,
            creditsEarn: ticketData.ticket.creditsEarn,
          };
        })
      )
      .subscribe((transformedTicket) => {
        this.ticket = transformedTicket;
        this.ticketUpdated.next(this.ticket);
      });
  }

  addTicket(
    ticketName: string,
    ticketDescription: string,
    ticketPrice: number,
    ticketCreditsPrice: number,
    ticketCreditsEarn: number
  ) {
    const now = new Date();
    const ticket: Ticket = {
      _id: null,
      name: ticketName,
      description: ticketDescription,
      price: ticketPrice,
      date: new Date(now.getTime()),
      finish: false,
      creatorId: null,
      creatorName: this.userService.getUser().name,
      creditsPrice: ticketCreditsPrice,
      creditsEarn: ticketCreditsEarn,
    };
    this.http
      .post<{ message: string; ticketId: string }>(
        this.externalHost + '/tickets',
        ticket
      )
      .subscribe((responseData) => {
        const id = responseData.ticketId;
        ticket._id = id;
        this.tickets.push(ticket);
        this.ticketsUpdated.next([...this.tickets]);
      });
  }

  removeTicket(ticketId: string) {
    this.http
      .delete(this.externalHost + '/tickets/' + ticketId)
      .subscribe((message) => {
        const updatedTicket = this.tickets.filter(
          (ticket) => ticket._id !== ticketId
        );
        this.tickets = updatedTicket;
        this.ticketsUpdated.next([...this.tickets]);
      });
  }
}
