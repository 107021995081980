import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { toSegments } from '@ionic/angular/directives/navigation/stack-utils';
import { Observable } from 'rxjs';
import { AuthService } from 'src/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const isAuth = this.authService.getIsAuth();
    const isAdmin = this.authService.getUserAdminStatus();
    const rout = route.url.map((segment) => segment.toString());

    if (!isAuth) {
      console.log('Not Auth');
      this.router.navigate(['/']);
    }

    if (rout[0] == 'admin' && isAdmin >= 1) {
      console.log('IS admin', isAdmin);
      this.router.navigate(['/home']);
    }
    return isAuth;
  }
}
// getResolvedUrl(route: ActivatedRouteSnapshot): string {
//     let url = route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/');
//     const queryParam = route.queryParamMap;
//     if (queryParam.keys.length > 0) {
//       url += '?' + queryParam.keys.map(key => queryParam.getAll(key).map(value => key + '=' + value).join('&')).join('&');
//     }
//     return url;
//   }
