import { Component, OnInit } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  user: any = {
    name: null,
    phone: null,
    password: null,
  };
  index: number;

  constructor(
    public toastController: ToastController,
    public popoverController: PopoverController,
    public userService: UserService
  ) {}

  ngOnInit() {}

  editUser(user: any) {
    if (this.index == 1) {
      this.userService.updateUserName(user);
    }
    if (this.index == 2) {
      this.userService.updateUserPhone(user);
    }
    if (this.index == 3) {
      this.userService.updateUserPassword(user);
    }
    this.presentToastSuccess();
  }

  async presentToastSuccess() {
    const toast = await this.toastController.create({
      message: 'Успешни Промени',
      duration: 1500,
    });
    toast.present();
    this.dismissPopover();
  }

  async dismissPopover(showAddressModal = false) {
    await this.popoverController.dismiss({ showAddress: showAddressModal });
  }
}
