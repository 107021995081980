import { Component, OnInit } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import { Service } from 'src/app/models/service';
import { ServiceService } from 'src/services/service.service';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.scss'],
})
export class AddServiceComponent implements OnInit {
  service: Service = <Service>{};
  constructor(
    public toastController: ToastController,
    public popoverController: PopoverController,
    public serviceService: ServiceService
  ) {}

  ngOnInit() {}

  addService(form: any) {
    this.serviceService.addService(
      form.value.name,
      form.value.description,
      form.value.price,
      form.value.creditsPrice,
      form.value.creditsEarn
    );
    this.presentToastSuccess();
  }

  async presentToastSuccess() {
    const toast = await this.toastController.create({
      message: 'Додадовте нов сервис!',
      duration: 2000,
    });
    toast.present();
    this.dismissPopover();
  }

  async dismissPopover(showAddressModal = false) {
    await this.popoverController.dismiss({ showAddress: showAddressModal });
  }
}
