import { Component, OnInit } from '@angular/core';
import {
  NavController,
  Platform,
  AlertController,
  MenuController,
} from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/services/auth.service';
import { UserService } from 'src/services/user.service';
import { User } from './models/user';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public userAdminStatus: number;
  public appPages = [
    { title: 'Дома', url: '/home', icon: 'home' },
    { title: 'Подесувања', url: '/settings', icon: 'settings' },
    { title: 'Одјависе', url: '/Logout', icon: 'log-out' },
  ];
  public adminPage = {
    title: 'Админ',
    url: '/admin',
    icon: 'git-network',
  };

  user: User = <User>{};
  userSub: Subscription;
  UserPhone: number;
  UserName: string;
  constructor(
    private menuCtrl: MenuController,
    private navCtrl: NavController,
    private platform: Platform,
    public alertController: AlertController,
    private authService: AuthService,
    public userService: UserService
  ) {
    this.authService.autoAuthUser();
    this.menuCtrl.enable(false);
  }
  ngOnInit() {
    this.authService.autoAuthUser();
    this.userSub = this.userService
      .getUserUpdateListener()
      .subscribe((user: User) => {
        this.UserPhone = user.phone;
        this.UserName = user.name;
        this.userAdminStatus = user.admin_status;
        this.user.theme = user.theme;
        if (this.user.theme != false) {
          document.body.setAttribute('color-theme', 'dark');
        } else {
          document.body.setAttribute('color-theme', 'light');
        }
      });
    //document.body.setAttribute('color-theme', 'dark');
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  logOut(pageTitle: string) {
    if (pageTitle == '/Logout') {
      this.authService.logOut();
    }
  }
  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: 'Are you sure?',
      message: 'You will <strong>exit</strong> the app!',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {},
        },
        {
          text: 'Okay',
          handler: () => {
            this.exitApp();
          },
        },
      ],
    });

    await alert.present();
  }

  exitApp() {
    navigator['app'].exitApp();
  }
}
