import { Component, OnInit } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import { TicketService } from 'src/services/ticket.service';
import { Ticket } from '../../models/ticket';
@Component({
  selector: 'app-cancel-ticket',
  templateUrl: './cancel-ticket.component.html',
  styleUrls: ['./cancel-ticket.component.scss'],
})
export class CancelTicketComponent implements OnInit {
  public ticket: Ticket;

  constructor(
    public toastController: ToastController,
    public popoverController: PopoverController,
    public ticketService: TicketService
  ) {}

  ngOnInit() {}

  async presentToastSuccess(ticket_id) {
    const toast = await this.toastController.create({
      message: 'Вашиот сервве откажан!',
      duration: 2000,
    });
    this.ticketService.removeTicket(ticket_id);
    toast.present();
    this.dismissPopover();
  }

  async dismissPopover(showAddressModal = false) {
    await this.popoverController.dismiss({ showAddress: showAddressModal });
  }
}
